import React from 'react';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import colors from "styles/colors";
import { Link, graphql } from 'gatsby';
import { RichText } from "prismic-reactjs";
import Button from "components/_ui/Button";
import Layout from "components/Layout";
import dimensions from "styles/dimensions";
import ph from "../images/product-hunt.svg"
import twitter from "../images/twitter.svg"
import Newsletter from "components/newsletter";




const ProjectHeroContainer = styled("div")`
    background: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    padding-top: 2.25em;
    margin-bottom: 3.5em;

    img {
        max-width: 600px;
    }
`

const ProjectTitle = styled("div") `
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
`

const ProjectBody = styled("div")`
    /* max-width: 550px; */
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }
`

const WorkLink = styled(Link)`
    margin-top: 3em;
    display: block;
    text-align: center;
`

const TestemonialTitle = styled("div")`
    font-size:1em;
    margin-bottom:0.3em;
    font-style: italic
`

const TestemonialAuthor = styled("div")`
    font-size:1em;
    text-align:right
`

const TestemonialContainer = styled("div")`
    border: 3px solid black;
    border-radius: 5px;
    width: 100%;
    padding:1em;
    margin-bottom:1%;


    @media(min-width:${dimensions.maxwidthTablet}px) {
       width:48%;
       margin: 1%
    }

    @media(min-width:${dimensions.maxwidthDesktop}px) {
       width:30%;
       margin: 1%;
    }
`

const AllTestemonials=styled("div")`
display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`

const Testemonial = ({testemonial }) => {
  var img = testemonial.testemonials_platform=='producthunt' ? <img src={ph} style={{width:'1em',height:'1em',marginRight:'0.3em'}} className="mr-1" alt="product hunt" /> : <img src={twitter} style={{width:'1em',height:'1em',marginRight:'0.3em'}} className="mr-1" alt="product hunt" />
  return (
    <TestemonialContainer>
      <TestemonialTitle>{img}{testemonial.testemonials_text}</TestemonialTitle>
      <TestemonialAuthor>{testemonial.testemonials_author}</TestemonialAuthor>
    </TestemonialContainer>
  )
}

const VisitLink = styled('a')`
    margin-top: 3em;
    display: block;
    text-align: center;
`

const ProjectLink = ({link, title}) => {

  return (
    <VisitLink href={link.url}>
    <Button className="Button--secondary">
        Visit {title}
    </Button>
    </VisitLink>
  )
}


const Project = ({ project, meta }) => {
    return (
        <>
        <Helmet>
            <title>{project.project_title[0].text} By Koby Ofek</title>
            <meta name="description" content={project.project_description[0].text} />
            <meta name="og:title" content={project.project_title[0].text + 'By Koby Ofek'} />
            <meta name="og:description" content={project.project_description[0].text} />
            <meta name="og:type" content='website' />
            <meta name="twitter:card" content='summary' />
            <meta name="twitter:creator" content='@kobyof' />
            <meta name="twitter:title" content={project.project_title[0].text + 'By Koby Ofek'} />
            <meta name="twitter:description" content={project.project_description[0].text} />
        </Helmet>

            <Layout>
                <ProjectTitle>
                    {RichText.render(project.project_title)}
                </ProjectTitle>
                {project.project_hero_image && (
                    <ProjectHeroContainer>
                        <img src={project.project_hero_image.url} alt="bees" />
                    </ProjectHeroContainer>
                )}
                <ProjectBody>

                    {project.testemonials!==null && project.testemonials.length!==0 &&
                      <AllTestemonials>
                      {project.testemonials.map(function(testemonial,i) {
                        debugger;
                      return (
                        <Testemonial testemonial={testemonial} key={i} />
                      )
                    })}
                    </AllTestemonials>
                    }

                    <ProjectLink link={project.project_link} title={project.project_title[0].text}/>

                    {RichText.render(project.project_description)}
                    <WorkLink to={"/work"}>
                        <Button className="Button--secondary">
                            See other work
                        </Button>
                    </WorkLink>
                </ProjectBody>
                <br />
                <Newsletter />
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const projectContent = data.prismic.allProjects.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Project project={projectContent} meta={meta}/>
    )
}

Project.propTypes = {
    project: PropTypes.object.isRequired,
};

export const query = graphql`
    query ProjectQuery($uid: String) {
        prismic {
            allProjects(uid: $uid) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        project_hero_image
                        project_description
                        project_link { ... on PRISMIC__ExternalLink {
                            _linkType
                            url
                        }
                      }
                        testemonials {
                          testemonials_author
                          testemonials_text
                          testemonials_platform
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
